<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  // import Nav from "./components/wnav.vue";
  export default {
    // components: { Nav },
    data() {
      return {
        token: localStorage.getItem("loginToken")
      }
    },
    mounted() {
      // console.log('e', this.$emit('getlist'))
    },
    computed: {
      key() {
        return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
      }
    },
  }
</script>
<style>
  .w-block {
    border-radius: 4px;
    background: #f5f5f5;
    color: #333;
    margin: 5px 0px;
    border: solid 1px #ccc
  }

  .el-table th {
    color: #333;
  }

  .el-table td {
    height: 40px
  }

  .el-dialog__wrapper {
    z-index: 999
  }

  .el-dialog__header {
    border-bottom: solid 1px #eceeef;
    background-color: #fcb410 !important;
    line-height: 24px;
    font-size: 18px;
    color: #fff;
  }

  .el-dialog__headerbtn {
    text-shadow: 0 1px 0 #ffffff;
    opacity: .5;
    background: transparent;
  }

  .el-dialog__footer {
    border-top: 1px solid #eceeef
  }

  .el-dialog__title {
    color: #fff
  }

  .swal2-container.swal2-center {
    z-index: 5000;
  }

  button:focus {
    outline: none
  }

  input[aria-hidden="true"] {
    display: none !important;
  }
</style>
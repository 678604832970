/**
 * Created by whale on 2021/03/26.
 */
import axios from "axios";
import Swal from "sweetalert2";
import Compressor from "compressorjs";
import ElementUI from 'element-ui'

export default {
  install: function (Vue) {
    Vue.prototype.whale = {
      remote: {
        getResult: function (opt) {
          let url = opt.url;
          let load = ElementUI.Loading.service({
            lock: true,
            background: "rgba(0, 0, 0, 0.2)"
          });
          // if (process.env.NODE_ENV==='development') url='/api'+opt.url;
          axios
            .post(url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then(function (resp) {
              load.close()
              // console.log("resp", resp.data);
              if (resp.data["Succeeded"]) {
                if (opt.completed !== undefined)
                  opt.completed(resp.data["Data"]);
              } else {
                if (opt.failure !== undefined) opt.failure(resp.data["Errors"]);
                else {
                  Swal.fire({
                    title: "出错啦",
                    text: resp.data["Errors"],
                    icon: "error",
                    cancelButtonText: "关闭",
                    showCancelButton: true,
                    showConfirmButton: false,
                  });
                }
              }
              if (opt.finally !== undefined) opt.finally();
            })
            .catch(function (error) {
              load.close()
              console.log("remote", error);
            });
        },
        getCollection: function (opt) {
          let url = opt.url;
          // if (process.env.NODE_ENV==='development') url='/api'+opt.url;
          axios
            .post(url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              // console.log("coll", resp);
              if (resp.data["Succeeded"]) {
                if (opt.completed !== undefined)
                  opt.completed(
                    resp.data.Data.DATA.ITEMS,
                    resp.data.Data.DATA.TOTAL
                  );
              } else {
                if (opt.failure !== undefined) opt.failure(resp.data.MESSAGE);
                else console.log("remote", resp.data["MESSAGE"]);
              }
            })
            .catch((e) => console.log("fetch error", e));
        },
        getThird: function (opt) {
          axios
            .post(opt.url, opt.params, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(function (resp) {
              if (opt.finally !== undefined) opt.finally(resp.data);
            })
            .catch(function (error) {
              console.log("remote", error);
            });
        },
        getExport: function (opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
              responseType: "blob",
            })
            .then((resp) => {
              // console.log(resp.data);
              opt.completed(resp.data);
            });
        },
        import: function (opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              // console.log(resp.data);
              opt.completed(resp.data.Data);
            });
        },
        uploadSign: function (opt) {
          axios
            .request({
              url: opt.url,
              method: "post",
              data: opt.data,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: localStorage.getItem("loginToken"),
              },
            })
            .then((resp) => {
              // console.log(resp.data);
              opt.completed(resp.data);
            });
        },
        getExportResult: function (opt) {
          let url = opt.url;
          // if (process.env.NODE_ENV==='development') url='/api'+opt.url;
          axios
            .post(url, JSON.stringify(opt.data || {}), {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("loginToken"),
              },
              responseType: "blob",
            })
            .then(function (resp) {
              // console.log("resp", resp);
              if (resp.data.type == "application/json") {
                resp.data.text().then(() => {
                  Swal.fire({
                    title: "出错",
                    text: '下载出错',
                    icon: "error",
                    cancelButtonText: "关闭",
                    showCancelButton: true,
                    showConfirmButton: false,
                  });
                });
                return;
              } else {
                if (opt.completed !== undefined)
                  opt.completed(resp.data);
              }
            })
            .catch(function (error) {
              console.log("remote", error);
            });
        },
      },
      grade: {
        lst: [
          { value: 0, name: "托班" },
          { value: 1, name: "小班" },
          { value: 2, name: "中班" },
          { value: 3, name: "大班" },
          { value: 4, name: "毕业班" },
        ],
        getName: function (code) {
          if (code == null)
            return "";
          return this.lst.find((g) => g.value === code).name;
        },
      },
      weeks: [
        { name: "周日", value: "0" },
        { name: "周一", value: "1" },
        { name: "周二", value: "2" },
        { name: "周三", value: "3" },
        { name: "周四", value: "4" },
        { name: "周五", value: "5" },
        { name: "周六", value: "6" },
      ],
      area: [
        { name: "市辖区", value: "330301" },
        { name: "鹿城区", value: "330302" },
        { name: "龙湾区", value: "330303" },
        { name: "瓯海区", value: "330304" },
        { name: "洞头区", value: "330305" },
        { name: "永嘉县", value: "330324" },
        { name: "平阳县", value: "330326" },
        { name: "苍南县", value: "330327" },
        { name: "文成县", value: "330328" },
        { name: "泰顺县", value: "330329" },
        { name: "温州经济技术开发区", value: "330371" },
        { name: "瑞安市", value: "330381" },
        { name: "乐清市", value: "330382" },
      ],
      roles: {
        ADMIN: "学校管理员",
        HEALTH: "保健教师",
      },
      delConfirm: (fn) => {
        Swal.fire({
          text: "真的要删除这条记录吗？",
          icon: "question",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showCancelButton: true,
        }).then((v) => {
          if (v.isConfirmed) fn();
        });
      },
      confirmText: (msg, fn) => {
        Swal.fire({
          text: msg,
          icon: "question",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          showCancelButton: true,
        }).then((v) => {
          if (v.isConfirmed) fn();
        });
      },
      downLoad(imgSrc, name) {
        //下载图片地址和图片名
        var image = new Image();
        // 解决跨域 Canvas 污染问题
        // image.setAttribute("crossOrigin", "anonymous");
        image.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
          var a = document.createElement("a"); // 生成一个a元素
          var event = new MouseEvent("click"); // 创建一个单击事件
          a.download = name || "photo"; // 设置图片名称
          a.href = url; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        };
        image.src = imgSrc;
      },
      downLoad2(url, name) {
        var link = document.createElement('a');
        link.style.display = "none";
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      downLoad3(url, name) {
        axios.get(url, { responseType: 'blob' }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }).catch(error => console.error('Error downloading file:', error));
      },
      getGuid() {
        function S4() {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
      },
      toast: {
        err(msg) {
          Swal.fire({
            title: "出错啦",
            text: msg,
            icon: "error",
            cancelButtonText: "关闭",
            showCancelButton: true,
            showConfirmButton: false,
          });
        },
        warn(msg) {
          Swal.fire({
            text: msg,
            icon: "warning",
            cancelButtonText: "关闭",
            showCancelButton: true,
            showConfirmButton: false,
          });
        },
        info(msg) {
          Swal.fire({
            text: msg,
            icon: "info",
            cancelButtonText: "关闭",
            showCancelButton: true,
            showConfirmButton: false,
          });
        },
      },
      image: {
        compress(opt) {
          if (opt.quality === undefined) opt.quality = 0.2;
          new Compressor(opt.file, {
            quality: opt.quality,
            convertSize: 1048576,
            success(ret) {
              opt.callback(ret);
            },
            error(err) {
              alert(err);
            },
          });
        },
        toB64(opt) {
          let reader = new FileReader();
          reader.readAsDataURL(opt.file);
          reader.onload = (ev) => {
            let arr = ev.target.result.split(",");
            opt.callback(arr[1]);
          };
        },
      },
      user: {
        get() {
          return JSON.parse(localStorage.getItem("userInfo"));
        },
      },
      hasPower(key) {
        var user = JSON.parse(localStorage.getItem("userInfo"));
        if (user && user.ROLE_BTN) {
          return (user.ROLE_BTN.indexOf(key) >= 0) || (user.ROLE == 'ADMIN');
        }
        if (user)
          return (user.ROLE == 'ADMIN');
        return false;
      }
    };
  },
};
